@import "../../styles/constants";

.loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: rgba($mainBg, 0.35);

  > .spinner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.loadingModal {
  position: relative;
  padding-bottom: 56.25%;
}

.inComponent {
  position: inherit;
}

.spinner {
  width: 4rem;
  height: 4rem;
  margin: 2rem auto;
  transform: translate3d(0, 0, 0);

  span {
    display: block;
    text-indent: -999rem;
  }
  &::before {
    content: "";
    width: 4rem;
    height: 4rem;
    display: block;

    background: url(../../../icons/logo.svg) no-repeat center / contain;
    animation: 3s linear infinite animate-logo-rotate;
    will-change: transform;
  }
}

@keyframes animate-logo-rotate {
  0% {
    transform: rotate(0deg);
  }
  5% {
    transform: rotate(85deg);
  }
  25% {
    transform: rotate(90deg);
  }
  30% {
    transform: rotate(175deg);
  }
  50% {
    transform: rotate(180deg);
  }
  55% {
    transform: rotate(265deg);
  }
  75% {
    transform: rotate(270deg);
  }
  80% {
    transform: rotate(355deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animation-rotate {
  0% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(450deg);
  }
}
