// <name> <weight> <style>
$variants: "Thin" 100 normal, "Light" 300 normal, "Regular" 400 normal,
  "Medium" 500 normal, "Italic" 400 italic;

@each $tuple in $variants {
  $name: nth($tuple, 1);

  @font-face {
    font-family: "Roboto";
    src: url("./Roboto-" + $name + ".woff2") format("woff2"),
      url("./Roboto-" + $name + ".woff") format("woff"),
      url("./Roboto-" + $name + ".ttf") format("truetype");
    font-weight: nth($tuple, 2);
    font-style: nth($tuple, 3);
  }
}
