//
// Fluent UI overrides
//

button.fui-Button {
  // TODO: 2023-03-06 michaeljohansen: Overriding border-radius works poorly
  //       with SplitButton, but we don't use SplitButton anywhere yet.
  border-radius: var(--borderRadiusSmall);
  font-weight: normal;
}

span.fui-Checkbox {
  svg {
    // Weird case: For some reason, the library `react-d3-tree` decided to add
    // `.rd3t-leaf-node { stroke: #000; }`. This makes the checkboxes, that can
    // toggle inventory nodes in the tree, have a black stroke around their
    // checkmark. This little line counter-acts that:
    stroke: none; // Tips: try $pureWhite here for a fatter checkmark everywhere
  }
}

div.fui-Checkbox__indicator {
  // spacingVerticalS is default, but a little too roomy. There were no good FUI
  // vars between spacingVerticalS and spacingVerticalXS, so 6px is hardcoded.
  margin-top: 6px;
  margin-bottom: 6px;
  // Critical for ChartFilter.tsx
  min-height: var(--spacingVerticalL);
  min-width: var(--spacingVerticalL);
}

label.fui-Checkbox__label {
  padding-top: var(--spacingVerticalXXS);
  padding-bottom: 0;
  padding-left: var(--spacingVerticalXXS);
}

div.fui-Tooltip__content {
  background-color: $greenClay;
}
