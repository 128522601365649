@import "constants";
@import "./fonts/roboto/index";
@import "fluent";

*,
*:before,
*:after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

html {
  min-width: 1000px;
  overflow-y: scroll;
  overflow-x: auto;
  background: $mainBg;
  color: $pureWhite;
  font-family: $fontFamilyMain;
  font-weight: $fontWeightNormal;
  font-size: 14px;
  line-height: 1.2;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  height: 100vh;

  /* Allow some scrolling past the end of the page. */
  padding: $topHeaderHeight 0 75vh 0;
}

@media (min-width: 1000px) {
  html {
    font-size: 15px;
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 1400px) {
  html {
    font-size: 17px;
  }
}

@media (min-width: 1600px) {
  html {
    font-size: 18px;
  }
}

@media (min-width: 2000px) {
  html {
    font-size: 19px;
  }
}

body,
button,
label,
input,
select,
textarea {
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: 1em;
}

th,
strong {
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  outline: none;
}

a:not([href]) {
  cursor: default;
}

button {
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;
  text-transform: inherit;
  letter-spacing: inherit;
  line-height: inherit;
}

button:disabled {
  pointer-events: none;
  cursor: not-allowed;
}

ul,
ol {
  list-style-type: none;
}

li {
  display: block;
}

abbr {
  text-decoration: none;
  font-variant: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

::-moz-focus-inner {
  border: none;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-ms-clear {
  display: none;
}

/* Remove the GoogleMap scroll help overlay. */
.gm-style-pbc {
  opacity: 0 !important;
}

/* Remove the GoogleMap keyboard shortcuts. */
.gmnoprint:first-of-type {
  display: none;
}

.ReactModal__Body--open {
  overflow-y: hidden;
}
